import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SSL_OP_ALLOW_UNSAFE_LEGACY_RENEGOTIATION } from 'node:constants';

import { getThemeLang, setThemeLang } from 'src/app/utils/util';
// import { ProfileService } from '../services/profile.service';

const languageKey = '__lang';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  defaultLanguage = getThemeLang();
  supportedLanguages: Language[] = [];

  constructor(
    private translate: TranslateService,
    // private _profileService:ProfileService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  configLanguages(){
    return new Promise((resolve,rejects)=>{
      var storeData = JSON.parse(localStorage.getItem('storeData'));
      this.supportedLanguages = [];
      if(storeData){
        
      }else{
        this.defaultLanguage = 'en'
        this.supportedLanguages.push({ code: 'en', direction: 'ltr', label: 'English', shorthand: 'en' })
        resolve(true);
      }
    })
  }

  init(): void {
    this.configLanguages().then(()=>{
      this.supportedLanguages.forEach(_lang=>{
        try{
          const data = require(`../../assets/i18n/${_lang.code}.json`)
          this.translate.setTranslation(_lang.code, data);          
        }catch(err){
          const data = require('../../assets/i18n/en.json')
          this.translate.setTranslation(_lang.code, data);
        }
      })
      const data = require('../../assets/i18n/en.json')
      this.translate.setTranslation('en', data);
      this.translate.setDefaultLang(this.defaultLanguage);
      this.translate.use(this.defaultLanguage);

      this.renderer.removeClass(document.body, 'ltr');
      this.renderer.removeClass(document.body, 'rtl');    
      if(this.translate.currentLang === 'ar'){
        this.renderer.addClass(document.body, 'rtl');
      }else{
        this.renderer.addClass(document.body, 'ltr');
      }

    })

  }

  // checkForDirectionChange(): void {
  //   this.renderer.removeClass(document.body, 'ltr');
  //   this.renderer.removeClass(document.body, 'rtl');
  //   this.renderer.addClass(document.body, this.direction);
  //   this.renderer.setAttribute(document.documentElement,'direction',this.direction);
  // }

  // set language(lang: string) {
  //   let language = lang || getThemeLang();
  //   const isSupportedLanguage = this.supportedLanguages.map((item) => item.code).includes(language);
  //   if (!isSupportedLanguage) {
  //     language = this.defaultLanguage;
  //   }
  //   setThemeLang(language);
  //   this.translate.use(language);
  //   this.checkForDirectionChange();
  // }

  get language(): string {
    // this.checkForDirectionChange()
    return this.translate.currentLang;
  }

  get languageShorthand(): string {
      let currentLang = this.supportedLanguages.find(item => item.code === this.translate.currentLang);
      return currentLang ? currentLang.shorthand : 'en';
  }

  get direction(): string {
    let currentDirection = this.supportedLanguages.find(item => item.code === this.translate.currentLang);
    return currentDirection ? currentDirection.direction : (this.translate.currentLang === 'ar' ? 'rtl' : 'ltr');
  }

  get languageLabel(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang).label;
  }

  get selectedlanguageIndex():number {
    return this.supportedLanguages.findIndex(item => item.code === this.translate.currentLang);
  }

}

export class Language {
  code: string;
  direction: string;
  label: string;
  shorthand: string;
}
