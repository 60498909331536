import { environment } from "src/environments/environment";

export var CONFIG = {
    apiUrl: environment.apiUrl,
    edelivery_apiUrl: environment.edelivery_apiUrl,
    eber_apiUrl: environment.eber_apiUrl,
    eservice_apiUrl: environment.eservice_apiUrl,
    esuper_apiUrl: environment.esuper_apiUrl,
    imageUrl: environment.imageUrl
}
