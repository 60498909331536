import { Component, OnInit, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import { LangService } from './shared/lang.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { Observable } from 'rxjs';
import { MessagingService } from './shared/messaging.service';
import { Helper } from './shared/helper';
import { SocketService } from './shared/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = true;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  token$: Observable<any>;
  message$: Observable<any>;
  showRequest = false;


  constructor(private langService: LangService, 
    private socket: SocketService,
    private renderer: Renderer2,private authService:AuthService,private messagingService: MessagingService) {}

  ngOnInit(): void {
    // this.socket.setupSocketConnection();
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.authService.autologin()
    this.langService.init();
  }
 
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }

}
