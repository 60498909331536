export const environment = {
  production: false,
  adminRoot: '/app',
  dashboardUrl: 'http://13.235.27.173',  
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  defaultColor: 'light.blueyale',
  // apiUrl: 'https://edelivery.elluminatiinc.net/v3',
  socketUrl: 'https://apiedelivery.elluminatiinc.net/v4',
  // imageUrl: 'https://apiedelivery.elluminatiinc.net/',
  // apiUrl: 'https://apiedelivery.elluminatiinc.net/v4',
  // edelivery_apiUrl: 'http://192.168.0.131:8100/v4',
  // eber_apiUrl: 'https://eber.elluminatiinc.net',
  // eservice_apiUrl: 'https://apieservice.elluminatiinc.net',
  // esuper_apiUrl: 'http://localhost:8100/v4',
  apiUrl: 'https://apiedelivery.elluminatiinc.net/v4',
  edelivery_apiUrl: 'https://apiedelivery.elluminatiinc.net/v4',
  eber_apiUrl: 'https://eber.elluminatiinc.net',
  eservice_apiUrl: 'https://apieservice.elluminatiinc.net',
  esuper_apiUrl: 'https://apiesuper.elluminatiinc.net/v4',
  imageUrl: 'https://apiedelivery.elluminatiinc.net/',
  firebase: {
    apiKey: "AIzaSyCLRAJRyv5421PbLgolNmL8BueMMOTRJU0",
    authDomain: "store-157305.firebaseapp.com",
    databaseURL: "https://store-157305.firebaseio.com",
    projectId: "store-157305",
    storageBucket: "store-157305.appspot.com",
    messagingSenderId: "1062107977876",
    appId: "1:1062107977876:web:ecaa423e96aa78dd36fc81"
  }

};
