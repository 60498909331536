import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundpipe',    
})
export class RoundPipe implements PipeTransform {

    constructor(){}

    transform(value: any): any {
        try{
            value = Number(value);
            if(value && !isNaN(value)){
                return value.toFixed(2);
            }else{
                return 0;
            }
        }catch(err){
            return value;
        }
    }
}