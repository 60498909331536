import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private _image_settings;
  private _settings;
  
  constructor(private helper: Helper) {}


  get image_settings(){
    return this._image_settings;
  }


  get settings(){
    return this._settings;
  }

  getCountryList(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_COUNTRY_LIST, {})
  }

  

}
