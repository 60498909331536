import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { join, resolve } from 'path';
import { BehaviorSubject, from } from 'rxjs';

import { getUserRole } from 'src/app/utils/util';
import { CommonService } from '../services/common.service';
import { Helper } from './helper';
import { LangService } from './lang.service';

export interface ISignInCredentials {
  email: string;
  device_token: string;
  device_type: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

export interface Store {
  _id: string,
  email:string,
  displayName:string,
  country_phone_code:string,
  location:string,
  servertoken:string,
  currency:string,
  is_use_item_tax: boolean
  is_tax_included: boolean
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  loginStoreData:any = {_id: '', servertoken: ''};

  private loginStore = new BehaviorSubject<any>(null);
  loginSession = this.loginStore.asObservable();
  private permissions = new BehaviorSubject<any>(null);
  authPermission =  this.permissions.asObservable();
  is_main_store_login = false;
  is_store_can_add_provider = false;
  is_store_can_create_group = false;
  is_store_can_edit_order = false;
  is_store_can_complete_order = false;
  is_ask_estimated_time_for_ready_order = false;
  is_document_uploaded = false;
  is_approved = false;
  is_email_verified = false;
  is_phone_number_verified = false;
  is_verified = false;
  is_upload_store_documents = false;
  is_provide_table_booking = false;
  is_store_mail_verification = false;
  is_store_sms_verification = false;

  constructor(private helper: Helper,
    private commonService:CommonService,
    private langService:LangService,
    private notifications: NotificationsService,) { }

  signIn(credentials: ISignInCredentials) {
    
  }

  check_verification(){
    return new Promise((resolve,rejects)=>{
      var setting =  this.commonService.settings
      this.is_store_mail_verification= setting.is_store_mail_verification
      this.is_store_sms_verification= setting.is_store_sms_verification
      if(this.is_phone_number_verified === true && this.is_email_verified === true){
        this.is_verified = true;
      }else if(!setting.is_store_mail_verification && !setting.is_store_sms_verification){
        this.is_verified = true;
      }else if(setting.is_store_sms_verification && setting.is_store_mail_verification){
        this.is_verified = this.is_phone_number_verified && this.is_email_verified;
      }else if(setting.is_store_sms_verification && !setting.is_store_mail_verification){
        this.is_verified = this.is_phone_number_verified;
      }else if(setting.is_store_mail_verification && !setting.is_store_sms_verification){
        this.is_verified = this.is_email_verified;
      }
      console.log('is verified' + this.is_verified)
      resolve(true)
    })
  }

  get isAdminVerificationRequired():boolean {
    return this.commonService.settings.is_store_sms_verification === true || this.commonService.settings.is_store_mail_verification === true
  }

  signOut(is_reload = false, is_redirect = true) {
    return new Promise((resolve, rejects) => {
      this.onResetData();
      if(is_reload){
        window.location.reload()
      } else if(is_redirect) {
        this.helper.router.navigate(['/store/login'])      
      }
    });
  }

  autologin() {
    // if(!this.is_verified){
    //   this.signOut();
    //   return;
    // }
    this.loginStoreData = JSON.parse(localStorage.getItem('storeData'));
    if (this.loginStoreData && this.loginStoreData.servertoken) {
      var storePermissions = JSON.parse(localStorage.getItem('storePermissions'));
      if(storePermissions){
        this.is_main_store_login = false;
        this.permissions.next(storePermissions)
      }else{
        this.is_main_store_login = true;
      }      
      this.loginStore.next(this.loginStoreData);
    } else {
      console.log(this.helper.router.url)
      this.signOut(false, false)
    }
  }

  getStore(){
    if(this.loginStoreData){
      return this.loginStoreData;
    }else{
      this.loginStoreData = JSON.parse(localStorage.getItem('storeData'));
      return this.loginStoreData;
    }
  }

  async getUser() {
    return { displayName: 'user', role: getUserRole() };
  }


  onResetData(){
    localStorage.removeItem('storeData');
    localStorage.removeItem('storePermissions');
    localStorage.removeItem('cartId');
    this.loginStoreData = undefined;
    this.loginStore.next(null);
    this.permissions.next(null);
    this.is_main_store_login = false;
    this.is_store_can_add_provider = false;
    this.is_store_can_create_group = false;
    this.is_store_can_edit_order = false;
    this.is_store_can_complete_order = false;
    this.is_ask_estimated_time_for_ready_order = false;
    this.is_document_uploaded = false;
    this.is_approved = false;
    this.is_email_verified = false;
    this.is_phone_number_verified = false;
    this.is_verified = false;
  }

}
