import {CanActivate,Router,ActivatedRouteSnapshot,RouterStateSnapshot,CanActivateChild,UrlTree,} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Helper } from './helper';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

  permissions: Array<any>;
  is_free_trial_expire: Boolean = false;
  is_payment_fail: Boolean = false;

  constructor(private authService: AuthService, private router: Router, private helper:Helper,private _commonService:CommonService) { }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree>  {
    if(this.authService.loginStoreData){
      return this.checkPaymentFlow(route,state)
    }else{      
      return false;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if(this.authService.loginStoreData){
      return this.checkPaymentFlow(route,state);
    }else{
      return false;
    }
  }


  checkPaymentFlow(route,state) : Promise<boolean | UrlTree> {
    return new Promise((resolve,rejects)=>{
      if (this.authService.is_main_store_login) {

        if(this._commonService.settings.is_upload_store_documents && !this.authService.is_document_uploaded){
          if(state.url.toString() === '/app/profile'){
            resolve(true);
          }else{
            this.helper.onError(null,'Documents not uploaded...')
            resolve(this.router.parseUrl('/app/profile'));
          }
        }


        if((this.authService.is_approved && this.authService.is_verified) || state.url === '/app/profile'){
          if(state.url === '/app/settings/payment'){
            resolve(true);
          } else if(state.url === '/app/dashboard'){
            resolve(true);
          } else if(state.url === '/app/users/delivery-boy-list'){
            this.authService.is_store_can_add_provider ? resolve(true) : resolve(this.router.parseUrl('/app/dashboard'));
          } else if(state.url === '/app/settings/table-booking-basic-settings' || state.url === '/app/settings/table-booking-cancellation-policy'){
            this.authService.is_provide_table_booking ? resolve(true) : resolve(this.router.parseUrl('/app/dashboard'));
          } else if(state.url === '/app/menu/category'){
            this.authService.is_store_can_create_group ? resolve(true) : resolve(this.router.parseUrl('/app/dashboard'));
          }  else {          
            resolve(true);
          }
        }else{
          if(!this.authService.is_approved){
            this.helper.onError(null,'Not Approved...')
          }else if(!this.authService.is_verified){
            this.helper.onError(null,'Not Verified...')
            this.authService.signOut();
          }
          resolve(this.router.parseUrl('/app/profile'));
        }
        
      } else {
        if (state.url === '/app/settings/payment') {
          resolve(false);
        } else if(state.url === '/app/dashboard'){
          resolve(true);
        } else{
          let check_permission ;
          if(route.data.auth){
            check_permission = route.data.auth;
            this.hasPermission(check_permission).then((has_permission)=>{
              has_permission === true ? resolve(true) : resolve(this.router.parseUrl('/app/dashboard'));
            })
          }else if(route.children[0] && route.children[0].data.auth){
            check_permission = route.children[0].data['auth'];
            this.hasPermission(check_permission).then((has_permission)=>{
              has_permission === true ? resolve(true) : resolve(this.router.parseUrl('/app/dashboard'));
            })
          }else{
            resolve(false);
          }
        }
      }
    });
  }


  hasPermission(check_permission): Promise<boolean> {
    return new Promise(async (resolve, rejects) => {     
      if(check_permission === 'noauth'){
        resolve(true);
      }else{
        if (!this.permissions) {
          this.authService.authPermission.subscribe(permissions => {
            if(permissions){
              this.permissions = permissions;
              resolve(this.permissions.includes(check_permission));
            }else{
              resolve(false);
            }
          })
        }else{
          resolve(this.permissions.includes(check_permission));
        }
      }
    })
  }
}