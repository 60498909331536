import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './clickoutside.directive';
import { HideIfUnauthorizedDirective } from './hide.directive';

@NgModule({
  declarations: [ClickOutsideDirective,HideIfUnauthorizedDirective],
  imports: [CommonModule],
  exports: [ClickOutsideDirective,HideIfUnauthorizedDirective],
})
export class DirectiveModule {}
