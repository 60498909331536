import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { exhaustMap, take } from 'rxjs/operators';
import { LangService } from '../shared/lang.service';

@Injectable()
export class ReqInterceptInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,private lang:LangService) { }

  my_headers:HttpHeaders;
  


  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return this.authService.loginSession.pipe(
        take(1),
        exhaustMap(store => {
            console.log(store)
            // if (!store) {
            //     return next.handle(req);
            // }
  
            var main_id = req.body && req.body.id ? req.body.id : undefined;;

            this.my_headers = new HttpHeaders()
                .append("Authorization", "Basic " + btoa("test:test"))
                .append("type", '0');
                console.log('set header')

              var modifiedRequest;

             
              modifiedRequest = req.clone({
                headers: this.my_headers  ,
                body:{...req.body}
              });
              

              return next.handle(modifiedRequest)
        })
    );

  }


}
